import React, {useEffect, useState, useRef, useCallback} from 'react';
import { createClient } from 'pexels';
import styled from 'styled-components';
import {useInterval} from "../Utils/useInterval";
import Img from './Img';
import {useSwipe} from "../Utils/useSwipe";

const PHOTOGRAPHER = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
const DETAILS = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  display: block;
  width: max-content;
  background: black;
  color: white;
  padding: 15px;
  font-weight: 400;
  border-radius: 4px;
  opacity: .8;
  font-size: 26px;
  box-shadow: 10px 10px 10px -11px rgba(0,0,0,0.75);
  
  &:hover {
    opacity: .9;
  }
  
  a {
    text-decoration: none;
    color: white;
  }
`;

const PHOTO = styled.a`
  text-decoration: none;
  font-size: 20px;
  display: block;
  
  span {
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PEXELS = styled.a`
  display: block;
  color: #ccc;
  font-size: 11px;
  text-decoration: none;
  margin-top: 5px;

  span {  
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Background = (props) => {
  const [photos, setPhotos] = useState([]);
  const [photoIndices, setPhotoIndices] = useState([]);
  const [prevIndices, setPrevIndices] = useState([]);
  const [prevPhoto, setPrevPhoto] = useState({});
  const [photo, setPhoto] = useState({});
  const [random, setRandom] = useState(0);
  const [fade, setFade] = useState(false);
  const [opacity, setOpacity] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [interval, setNewInterval] = useState(10000);
  const [rotate, setRotate] = useState(window?.innerHeight < window?.innerWidth ? 'landscape' : 'portait');
  const [orientation, setOrientation] = useState(window?.orientation);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(null);

  const client = useRef('pexels')
  const maxResults = 100;

  const { query, setQuery } = props;

  const swipeHandlers = useSwipe({ onSwipedLeft: () => loadNext(), onSwipedRight: () => loadPrevious() });

  const getNewRandom = useCallback(() => {
    const _randomIndex = Math.floor(Math.random()*photoIndices.length);
    const _random = photoIndices.splice(_randomIndex,1);

    let _randomNumber = null;
    _random.forEach((e) => {
      _randomNumber = e;
    })

    return _randomNumber;
  }, [photoIndices])

  const loadPrevious = useCallback(() => {
    if (prevIndices.length > 0) {
      setRandom(prevIndices.pop());
    }
  }, [prevIndices])

  const loadNext = useCallback(() => {
    if (photoIndices.length > 0 && random) {
      setPrevIndices([...prevIndices, random])
      setRandom(getNewRandom());
    }
  }, [photoIndices, getNewRandom, random, prevIndices])

  useEffect(() => {
    const loadPrev = (e) => {
      if (e.key === 'ArrowLeft') {
        loadPrevious();
      }
      if (e.key === 'ArrowRight') {
        loadNext();
      }
    }
    window.addEventListener("keydown", loadPrev, false);

    return () => {
      window.removeEventListener("keydown", loadPrev, false);
    }
  }, [prevIndices, loadPrevious, loadNext]);


  useInterval(() => {
    setPrevPhoto(photo);
    setPrevIndices([...prevIndices, random])
    setRandom(getNewRandom());
    if (photoIndices.length === 0) { // we have run out of indices
      if (((page) * maxResults) < totalResults) { // we can get a new page
        setPage(page + 1);
      } else if (totalResults < maxResults) { // we have less results than what we load in one go, just reset the indices
        setTimeout(() => {
          setPhotoIndices([...Array(totalResults).keys()])
        }, interval);
      } else { // we are at the last page, reset the page to the first
        setPage(1);
      }
    }
  }, interval)

  useEffect(() => {
    client.current = createClient(process.env.REACT_APP_PEXELS_API_KEY);
    }, []);

  useEffect(() => {
    console.log('prevIndices',prevIndices);
    }, [prevIndices]);

  useEffect(() => {
    if (photoIndices.length > 0) {
      setRandom(getNewRandom());
    }
  }, [photoIndices, getNewRandom]);

  // detect screen orientation changes and force a reload of the pictures in the correct viewbox
  useEffect(() => {
    const orientationHasChanged = () => {
      setOrientation(window.orientation); //for the time being this fixes it on ios devices
      setRotate(window?.innerHeight < window?.innerWidth ? 'landscape' : 'portait'); // annoying workaround cause safari doesn't understand window.screen.orientation
    };

    window.addEventListener("resize", orientationHasChanged, false);

    return () => {
      window.removeEventListener("resize", orientationHasChanged, false);
    }
  }, []);

  // detect screen orientation changes and force a reload of the pictures in the correct viewbox
  useEffect(() => {
    setTimeout(() => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }, 100);
  }, [rotate, orientation]);

  useEffect(() => {
    if (client?.current?.photos !== undefined) {
      setNewInterval(99999); // wait with reloading the next image
      if (query === null) {
        client.current.photos.curated({ per_page: maxResults, page }).then(_photos => {
          setPhotos(_photos?.photos);
          setPhotoIndices([...Array(maxResults).keys()]);
          setTotalResults(_photos?.total_results);
          setPrevIndices([])
        });
      } else {
        client.current.photos.search({ query, per_page: maxResults, page }).then(_photos => {
          if (_photos?.total_results === 0) {
            setQuery(null);
          }
          setPhotos(_photos?.photos);
          setPhotoIndices([...Array(_photos?.total_results < maxResults * page ? _photos?.total_results - ((page - 1) * maxResults) : maxResults).keys()]);
          setRandom(0);
          setTotalResults(_photos?.total_results);
          setPrevIndices([])
        });
      }
    }
  }, [query, setQuery, page]);

  useEffect(() => {
    if (photos !== undefined) {
      photos.forEach((pic) => {
        // prefetch all scaled down images, slow....
        const img = new Image();
        img.src = `${pic.src.original}?h=${window.innerHeight}&w=${window.innerWidth}`;
      });
      setNewInterval(8000); // return the interval to normal
    }
  }, [photos]);

  useEffect(() => {
    if (photos !== undefined && random !== null) {
      const _photo = photos?.[random];
      if (_photo !== undefined) {
        setPhoto({..._photo, scale: (_photo.width / _photo.height) / (window.innerWidth / window.innerHeight)});
      }
    }
  }, [photos, random]);

  useEffect(() => {
    if (prevPhoto?.src !== undefined) {
      setZoom(1);
      setOpacity(1);
      setTimeout(() => {
        setFade(true);
        setOpacity(0);
        setZoom(5);
        setTimeout(() => {
          setFade(false);
          setPrevPhoto(null);
        }, 6000);
      }, 100);
    }
  }, [prevPhoto]);

  return (<div {...swipeHandlers}>
    <Img photo={photo} width={width} height={height}/>
    <Img photo={prevPhoto} fade={fade} opacity={opacity} zoom={zoom} width={width} height={height}/>
    <DETAILS>
      <PHOTO href={photo?.url} target={'_blank'}><span>Photo</span> by</PHOTO>
      <PHOTOGRAPHER href={photo?.photographer_url}>{photo?.photographer}</PHOTOGRAPHER>
      <PEXELS href={'https://pexels.com'}>Provided by <span>Pexels</span></PEXELS>
    </DETAILS>
  </div>);

}

export default Background;
